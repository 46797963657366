import { useCallback, useContext } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/drappery_home.png'
import catDrappery2 from '../images/upholstery_home.png'

import CollectionNew from '../images/home_drapery.png'
import Collection2New from '../images/home_upholstery.png'
import Collection3New from '../images/home_contract.png'


import Wrapper from "./Wrapper";
import { NavLink } from "react-router-dom";
import { Slider } from "../Library/Module";
import SimpleSlider from "../components/Slider";

interface HomeProps {
    link: string;
}

const HomePage = ({ link }: HomeProps): JSX.Element => {
    const { orgUser } = useContext(ThemeContext)

    return (
        <>
        <SimpleSlider></SimpleSlider>
            <div className="container pt-4 pb-2">
                <div className="new_collection section">
                    <h1 className="text-center">BROWSE OUR NEW COLLECTIONS</h1>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <img src={Collection} alt="" title="" />
                            <NavLink className="btn btn-primary" to={'category/drappery'}>View Collection</NavLink>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <img src={Collection2} alt="" title="" />
                            <NavLink className="btn btn-primary" to={'category/upholstery'}>View Collection</NavLink>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <img src={Collection3} alt="" title="" />
                            <NavLink className="btn btn-primary" to={'category/contracts'}>View Collection</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-2 pb-2">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="section">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <h1>Drapery</h1>
                                    <p>Our linen curtains are a stylish and elegant addition to any home or office space. Made from high-quality linen fibers, these curtains are lightweight, breathable, and durable. Linen curtains come in a variety of colors and textures, making it easy to find the perfect match for any décor.</p>
                                    <p>Known for its softness and durability, making it a great choice for curtains that will be used frequently.Overall, linen curtains are a versatile and stylish choice for any home or office. Their natural material, light-blocking properties, and durability make them a practical choice, while their elegant appearance adds a touch of luxury to any space.</p>
                                    <NavLink className="btn btn-primary" to={'category/drappery'}>VIEW CATALOGUE</NavLink>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <img src={catDrappery} alt="" title="" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="section">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <h1>Upholstery</h1>
                                    <p>Our upholstery range includes a wide range of fabrics, designs, and textures
                                        that can be customized to meet the specific needs and preferences of our customers.
                                        We use only the finest materials that are durable, easy to clean, and eco-friendly.</p>
                                    <p>Our skilled craftsmen have years of experience in creating bespoke upholstery for
                                        different types of furniture, including chairs, sofas, cttcmans, and benches. We use
                                        state-of-the-art equipment and technigues to ensure that every piece of upholstery
                                        is crafted to perfection.</p>

                                    <NavLink className="btn btn-primary" to={'category/upholstery'}>VIEW CATALOGUE</NavLink>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <img src={catDrappery2} alt="" title="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomePage;
