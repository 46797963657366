import Facebook from '../images/social_facebook.png'
import Instagram from '../images/social_instagram.png'
import Twitter from '../images/social_twitter.png'
import Youtube from '../images/social_youtube.png'
import Icon1 from '../images/social_icon1.png'

import Contracts2 from '../images/Contracts_home2.png'
import Contracts from '../images/Contracts_home.png'
import { NavLink } from 'react-router-dom'
const Footer = () => {
    return (<>
        <div className="container pt-2 pb-2">
            <div className="section">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <h1 className="">Contracts</h1>
                        <p>D3 Contract fabrics meets flame-retardant and IMO standard while being luxurious and high performance at the same time. Commercial interiors such as offices, schools, universities, cinema, auditoria, bars, restaurants and healthcare must all adhere to additional standards and requirements that exceed the performance requirements of fabrics used for domestic/residential interiors. We provides designers, specifiers and architects extensive range of fabric selection with new and trendy designs and colors that will transform interiors while meeting fire-retardant standards and fitting a wide range of project budgets.</p>
                        <NavLink className="btn btn-primary" to={'category/contracts'}>VIEW CATALOGUE</NavLink>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <img src={Contracts} alt="" title="" />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <img src={Contracts2} alt="" title="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-2 pb-2">
            <div className="section">
                <h1 className="">CONTACT US</h1>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <p>Address: #178, Dubai Textile City, Dubai, United Arab Emirates, PO Box 4496</p>
                    </div>                                                                                                                                                                                                              
                    <div className="col-md-4 col-sm-12">
                        <p className="m-0">P: +971 4 8785797</p>
                        <p className="m-0">E: info@dynamicfabrics.net</p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="social">
                            <ul>
                                <li>
                                    <a className="social_fb" href="https://www.google.com/">
                                        <img src={Facebook} alt="" title="" />
                                    </a>
                                </li>
                                <li>
                                    <a className="social_fb" href="https://www.google.com/">
                                        <img src={Instagram} alt="" title="" />
                                    </a>
                                </li>
                                <li>
                                    <a className="social_fb" href="https://www.google.com/">
                                        <img src={Twitter} alt="" title="" />
                                    </a>
                                </li>
                                <li>
                                    <a className="social_fb" href="https://www.google.com/">
                                        <img src={Icon1} alt="" title="" />
                                    </a>
                                </li>
                                <li>
                                    <a className="social_fb" href="https://www.google.com/">
                                        <img src={Youtube} alt="" title="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer">
            Privacy Policy | Copyright: All rights reserved © 2023 Dynamic Design Decor
        </div>
    </>)
}
export default Footer